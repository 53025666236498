import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { Twitter, LinkedIn, YouTube, GitHub, Email, HeadsetMic, Web } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';


export default function Footer() {
    const theme = useTheme();

    return (
        <React.Fragment>
            <footer className="page-footer" style={{backgroundColor: theme.palette.background.default}}>
                <Grid display="flex" alignItems="center" flexDirection="column" paddingTop="10px" paddingBottom="10px">
                    <Typography variant="subtitle1" color="textPrimary">Get In Touch</Typography>
                    <Stack direction="row" spacing={3} style={{marginTop: 4}}>
                        <Link href="https://www.linkedin.com/company/bryanlabs" target="_blank" rel="noreferrer">
                            <LinkedIn />
                        </Link>
                        <Link href="https://twitter.com/bryanlabs" target="_blank" rel="noreferrer">
                            <Twitter />
                        </Link>
                        <Link href="https://discord.gg/GVrA9PqQAq" target="_blank" rel="noreferrer">
                            <HeadsetMic />
                        </Link>
                        <Link href="https://www.youtube.com/channel/UCGgtZQ6PKNBa2O7hRnNRSKw" target="_blank" rel="noreferrer">
                            <YouTube />
                            </Link>
                        <Link href="https://github.com/bryanlabs" target="_blank" rel="noreferrer">
                            <GitHub />
                        </Link>
                        <Link href="mailto:info@bryanlabs.net" target="_blank" rel="noreferrer">
                            <Email />
                        </Link>
                        <Link href="https://bryanlabs.net/" target="_blank" rel="noreferrer">
                            <Web />
                        </Link>
                    </Stack>
                </Grid>
            </footer>
        </React.Fragment>
    );
}
