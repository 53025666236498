import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import {ReactComponent as DarkLogo} from "./img/svg/brand/logo+wordmark-dark-noborder.svg"
import {ReactComponent as LightLogo} from "./img/svg/brand/logo+wordmark-white-noborder.svg"
import { hexToRgbA } from './utils/colors';
import { Switch } from '@mui/material';
import { DarkMode, LightMode, OpenInNew, Menu, FileDownload, Article } from '@mui/icons-material';
import { useState } from "react";

const sidebarWidth = 220;

const logoStyle = {width: 200, height: "auto", display: "block"};
const smallLogoStyle = {width: 140, height: "auto", display: "block"};

const locationIcons = {
    "App": <FileDownload fontSize="sm"></FileDownload>,
    "About": <Article fontSize="sm"></Article>
}

export default function Header({appNavs, onNavChange, onToggleTheme, currentAppNav}) {
    const [open, setOpen] = React.useState(false);

    const theme = useTheme();
    const mediumUp = useMediaQuery(theme.breakpoints.up('lg'));
    const xsmallUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [lightThemeOn, setLightThemeOn] = useState(theme.name === "light" ? true: false);

    const toggleThemeSwitch = (on) => {
        setLightThemeOn(on);
        onToggleTheme(on);
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="fixed" style={{backgroundColor: theme.palette.background.paper, boxShadow: `0 1px 2px ${hexToRgbA(theme.palette.primary.main, 0.1)}`, zIndex: 10000}}  >
                    <Toolbar sx={{justifyContent: "space-between", backgroundColor: theme.palette.background.paper}} disableGutters>
                        <div style={{display: "flex", alignItems: "center" ,justifyContent: "center"}}>
                            {
                                mediumUp ? null:
                                <IconButton
                                    color="black"
                                    aria-label="open drawer"
                                    onClick={() => {setOpen(!open)}}
                                    edge="start"
                                    sx={{ color: theme.palette.primary.main, paddingLeft: 3 }}
                                >
                                    <Menu />
                                </IconButton>
                            }
                            <Stack>
                                <Link sx={{textDecoration: "none", paddingLeft: xsmallUp ? 2.5: 0}} href="https://app.sycamore.tax">
                                    {
                                        theme.name === "light" ?
                                            (xsmallUp ? <DarkLogo style={logoStyle}/>: <DarkLogo style={smallLogoStyle}/>):
                                            (xsmallUp ? <LightLogo style={logoStyle}/>: <LightLogo style={smallLogoStyle}/>)
                                    }
                                </Link>
                            </Stack>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {theme.name === "light" ? <LightMode style={{color: theme.palette.secondary.main, marginRight: -5}} fontSize="sm"/> : <DarkMode style={{color: theme.palette.primary.main, marginRight: -5}} fontSize="sm"/>}
                            <Switch checked={lightThemeOn} onChange={() => {toggleThemeSwitch(!lightThemeOn)}} sx={{marginRight: mediumUp ? 0: 3}}/>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant={mediumUp ? "permanent": "temporary"}
                    open={open}
                    sx={{
                        width: sidebarWidth,
                        flexShrink: 0,
                        height: "100%",
                        [`& .MuiDrawer-paper`]: { width: sidebarWidth, boxSizing: 'border-box' },
                        boxShadow: `0 1px 2px ${hexToRgbA(theme.palette.primary.main, 0.5)}`, zIndex: 9999
                    }}
                >
                    <Toolbar />
                    <Stack sx={{ overflow: 'auto', marginTop: 4, paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }} spacing={2}>
                        {appNavs.map((location) => {
                            let icon = locationIcons[location];
                            let extraStyles = {};

                            if (currentAppNav === location){
                                extraStyles.backgroundColor = theme.palette.appNav.selected;
                                extraStyles["&:hover"] = {backgroundColor: theme.palette.appNav.selected};
                            }
                            return <Button
                                sx={{
                                    borderRadius: 4,
                                    justifyContent: "flex-start",
                                    ...extraStyles
                                }}
                                key={location}
                                onClick={() => {onNavChange(location)}}
                            >
                                {icon ? <React.Fragment>{icon}&nbsp;</React.Fragment>: null}{location}
                            </Button>;
                        })}
                        <Link href="https://wallet.keplr.app/chains/osmosis?modal=validator&chain=osmosis-1&validator_address=osmovaloper1dnmz4yzv73lr3lmauuaa0wpwn8zm8s20lg3pg9" target="_blank" style={{width: "100%"}}>
                            <Button
                                sx={{
                                    borderRadius: 4,
                                    width: "100%",
                                    justifyContent: "flex-start"
                                }}
                            >
                                <OpenInNew fontSize="sm"></OpenInNew>&nbsp;Delegate
                            </Button>
                        </Link>
                        <Link href="https://github.com/bryanlabs/cosmos-tax-cli/issues" target="_blank" style={{width: "100%"}}>
                            <Button
                                sx={{
                                    borderRadius: 4,
                                    width: "100%",
                                    justifyContent: "flex-start"
                                }}
                            >
                                <OpenInNew fontSize="sm"></OpenInNew>&nbsp;Report an Issue
                            </Button>
                        </Link>
                    </Stack>
                </Drawer>
                </Box>
            </React.Fragment>
    );
}
